import {
	CheckBadgeIcon,
	DocumentTextIcon,
	CurrencyDollarIcon,
} from '@heroicons/react/24/solid'
import { type MetaFunction } from '@remix-run/node'
import { type ReactNode, useState } from 'react'
import { SearchBar } from '#app/components/search-bar.js'

export const meta: MetaFunction = () => [
	{
		title: 'Top Study Documents, Lecture Notes, & Exam Guides | SkooDocs',
	},
	{
		name: 'description',
		content:
			'Access the best study materials, lecture notes, exam guides, and premium resources uploaded by students worldwide. Join SkooDocs and excel in your studies today!',
	},
	{
		name: 'keywords',
		content:
			'study documents, lecture notes, exam guides, student resources, educational materials, premium notes, SkooDocs, high school, university, academic success',
	},
	{
		name: 'og:title',
		content: 'SkooDocs - Top Study Documents & Exam Guides for Students',
	},
	{
		name: 'og:description',
		content:
			'Explore our vast library of premium study materials, exam guides, and student resources. Achieve academic success with SkooDocs!',
	},
	{
		name: 'og:image',
		content: '/img/og.jpg',
	},
	{
		name: 'og:url',
		content: 'https://skoodocs.com',
	},
	{
		name: 'twitter:card',
		content: 'summary_large_image',
	},
	{
		name: 'twitter:title',
		content: 'Find Top Study Documents on SkooDocs',
	},
	{
		name: 'twitter:description',
		content:
			'Join SkooDocs and discover the best student-uploaded study guides and resources for high school and university students.',
	},
	{
		name: 'twitter:image',
		content: '/img/pg.jpg',
	},
	{
		'script:ld+json': {
			'@context': 'https://schema.org',
			'@type': 'WebSite',
			name: 'SkooDocs',
			url: 'https://skoodocs.com',
			potentialAction: {
				'@type': 'SearchAction',
				target: 'https://skoodocs.com/documents?search={search_term_string}',
				'query-input': 'required name=search_term_string',
			},
		},
	},
]

export default function Index() {
	const [activeIndex, setActiveIndex] = useState<number | null>(null)

	const faqData = [
		{
			question: 'What study materials can I find here?',
			answer:
				'We provide lecture notes, exam guides, flashcards, and study tips for various subjects. All materials are student-uploaded and reviewed.',
		},
		{
			question: 'How do I know if the documents are reliable?',
			answer:
				'Each document is reviewed and rated by other students, giving you confidence in its quality and relevance to your studies.',
		},
		{
			question: 'Is the platform free to use?',
			answer:
				'Many of the resources are free, but there are also premium materials available for purchase to support platform sustainability.',
		},
		{
			question: 'How do I upload my own study materials?',
			answer:
				'After signing in, you can upload materials through your dashboard. Set your price or offer them for free to help other students.',
		},
		{
			question: 'What if I need help?',
			answer:
				'If you encounter any issues, our support team is available via live chat or through the Contact Us page for prompt assistance.',
		},
	]

	const toggleAccordion = (index: number) => {
		setActiveIndex(activeIndex === index ? null : index)
	}

	return (
		<main className="bg-background font-sans text-foreground">
			{/* Hero Section */}
			<section className="relative flex h-screen flex-col items-center justify-center bg-gradient-to-b from-primary to-secondary p-6 pt-24 dark:from-gray-800 dark:to-gray-900">
				<div className="relative max-w-4xl text-center">
					<h1 className="text-4xl font-extrabold text-primary-foreground dark:text-white md:text-5xl lg:text-6xl">
						Find Top Study Documents & Exam Guides
					</h1>
					<p className="mt-4 text-lg text-muted-foreground dark:text-gray-300 md:text-xl">
						Access a wide range of premium study materials, lecture notes, and
						exam guides to excel in your high school or university courses.
					</p>
				</div>

				<div className="relative mt-10 w-full max-w-3xl p-4 transition-transform duration-300 hover:scale-105">
					<div className="w-full rounded-lg bg-card p-4 shadow-lg dark:bg-gray-800 dark:shadow-gray-900">
						<SearchBar status="idle" autoFocus autoSubmit />
					</div>
				</div>
			</section>

			{/* Highlighted Features Section */}
			<section className="bg-accent py-20 dark:bg-gray-800">
				<div className="mx-auto max-w-6xl px-4 text-center sm:px-6 lg:px-8">
					<h2 className="text-3xl font-bold text-primary-foreground dark:text-white md:text-4xl lg:text-5xl">
						Why Choose SkooDocs for Your Study Needs?
					</h2>
					<p className="mt-6 text-lg text-muted-foreground dark:text-gray-300 md:text-xl">
						Our platform is tailored to help students achieve academic success
						by providing top-quality learning resources.
					</p>
					<div className="mt-16 grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
						<FeatureCard
							icon={
								<CheckBadgeIcon
									className="h-14 w-14 text-accent dark:text-purple-400"
									aria-hidden="true"
								/>
							}
							title="Reviewed Quality"
							description="Every document undergoes a quality check to ensure it's useful and relevant."
						/>
						<FeatureCard
							icon={
								<DocumentTextIcon
									className="h-14 w-14 text-accent dark:text-purple-400"
									aria-hidden="true"
								/>
							}
							title="Comprehensive Library"
							description="Explore our vast collection of notes, guides, and exam preparation resources across multiple subjects."
						/>
						<FeatureCard
							icon={
								<CurrencyDollarIcon
									className="h-14 w-14 text-accent dark:text-purple-400"
									aria-hidden="true"
								/>
							}
							title="Budget-Friendly"
							description="Access affordable premium materials, while free resources are also available."
						/>
					</div>
				</div>
			</section>

			{/* FAQ Section */}
			<section className="bg-secondary py-20 dark:bg-gray-900">
				<div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
					<h2 className="text-center text-3xl font-bold text-primary-foreground dark:text-white md:text-4xl lg:text-5xl">
						Frequently Asked Questions
					</h2>
					<p className="mt-6 text-center text-lg text-muted-foreground dark:text-gray-300 md:text-xl">
						Learn more about our platform and how it works.
					</p>
					<div className="mt-12">
						{faqData.map((faq, index) => (
							<div
								key={index}
								className={`mb-4 rounded-lg bg-card p-6 shadow-md transition-all duration-300 dark:bg-gray-800 dark:shadow-gray-900 ${
									activeIndex === index
										? 'border-l-4 border-primary dark:border-purple-400'
										: ''
								}`}
							>
								<h3
									className="flex cursor-pointer items-center justify-between text-base font-semibold dark:text-white md:text-lg"
									onClick={() => toggleAccordion(index)}
								>
									{faq.question}
									<span className="ml-2 transition-transform duration-200">
										{activeIndex === index ? '−' : '+'}
									</span>
								</h3>
								{activeIndex === index && (
									<p className="mt-3 text-sm text-muted-foreground dark:text-gray-400 md:text-base">
										{faq.answer}
									</p>
								)}
							</div>
						))}
					</div>
				</div>
			</section>

			{/* Call-to-Action (CTA) Section */}
			<section className="relative bg-gradient-to-r from-primary to-secondary py-20 text-center text-primary-foreground dark:bg-gray-800 dark:text-white">
				<div className="absolute inset-0">
					{/* Darker overlay for dark mode */}
					<div className="absolute inset-0 bg-gradient-to-r from-gray-900 to-gray-800 opacity-60 dark:opacity-80"></div>
					<img
						src="/img/cta-image.jpg"
						alt="Study resources"
						className="h-full w-full object-cover opacity-20 dark:opacity-25"
					/>
				</div>
				<div className="relative mx-auto max-w-4xl">
					<h2 className="text-3xl font-bold md:text-4xl lg:text-5xl">
						Start Learning with the Best Resources
					</h2>
					<p className="mt-6 text-base md:text-lg">
						Join a community of learners and access the best study materials
						online.
					</p>
					<a
						href="/signup"
						className="mt-10 inline-block rounded-full bg-destructive px-10 py-4 font-semibold text-destructive-foreground shadow-lg transition-transform duration-300 hover:scale-105 hover:bg-destructive/90 dark:bg-purple-500 dark:text-white dark:hover:bg-purple-400"
					>
						Sign Up for Free
					</a>
				</div>
			</section>

			{/* Testimonials Section */}
			<section className="bg-accent py-20 dark:bg-gray-800">
				<div className="mx-auto max-w-6xl px-4 text-center sm:px-6 lg:px-8">
					<h2 className="text-3xl font-bold text-primary-foreground dark:text-white md:text-4xl lg:text-5xl">
						What Our Students Are Saying
					</h2>
					<div className="mt-16 grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
						<TestimonialCard
							imageSrc="/img/student1.jpg"
							altText="Alex"
							feedback="An invaluable resource for my studies. The search functionality is a game-changer!"
							name="Alex, Computer Science Student"
						/>
						<TestimonialCard
							imageSrc="/img/student2.jpg"
							altText="Maria"
							feedback="I found study guides that helped me ace my exams. Highly recommend!"
							name="Maria, Biology Major"
						/>
						<TestimonialCard
							imageSrc="/img/student3.jpg"
							altText="Jamal"
							feedback="Being able to share my notes with others has been so rewarding. It's a real community!"
							name="Jamal, History Enthusiast"
						/>
					</div>
				</div>
			</section>
		</main>
	)
}

// FeatureCard Component
interface FeatureCardProps {
	icon: ReactNode
	title: string
	description: string
}

function FeatureCard({ icon, title, description }: FeatureCardProps) {
	return (
		<div className="rounded-lg bg-card p-6 shadow-md transition-transform duration-300 hover:scale-105 hover:shadow-xl dark:bg-gray-800 dark:shadow-gray-900">
			<div className="mx-auto mb-4 h-14 w-14">{icon}</div>
			<h3 className="text-base font-semibold text-primary-foreground dark:text-white md:text-lg">
				{title}
			</h3>
			<p className="mt-3 text-sm text-muted-foreground dark:text-gray-400 md:text-base">
				{description}
			</p>
		</div>
	)
}

// TestimonialCard Component
interface TestimonialCardProps {
	imageSrc: string
	altText: string
	feedback: string
	name: string
}

function TestimonialCard({
	imageSrc,
	altText,
	feedback,
	name,
}: TestimonialCardProps) {
	return (
		<div className="rounded-lg bg-card p-6 shadow-lg transition-shadow duration-300 hover:shadow-2xl dark:bg-gray-800 dark:shadow-gray-900">
			<img
				src={imageSrc}
				alt={altText}
				className="mx-auto mb-4 h-16 w-16 rounded-full object-cover"
			/>
			<p className="text-sm text-muted-foreground dark:text-gray-400 md:text-base">
				"{feedback}"
			</p>
			<p className="mt-4 text-base font-medium text-primary-foreground dark:text-white md:text-lg">
				- {name}
			</p>
		</div>
	)
}
